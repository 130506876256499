import {PovoleneTypyPriloh} from '@eon.cz/apollo13-graphql-web';
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import {ChangeEvent, ComponentType, ElementType, ReactNode} from 'react';
import {Controller, FieldError, useFormContext} from 'react-hook-form';
import {useIntl} from 'react-intl';
import {KeyboardDatePickerField} from '../date/KeyboardDatePickerField';
import {PrilohaBase, PrilohaKNahrani} from '../files/utils/PrilohyUtils';
import {FIELD_WITH_ERROR_FLASH_CLASS} from '../helperComponents/ErrorFocus';
import {NumberFormatCustom} from '../helperComponents/NumberFormatCustom';
import {TooltipIcon} from '../helperComponents/TooltipIcon';

type SelectData = string;

type DateProps = {
    readonly minDate?: Date;
    readonly maxDate?: Date;
    readonly disableFuture?: boolean;
};
type FileProps = {
    readonly handleDelete?: (prilohaId: string) => Promise<void>;
    /**
     * Seznam archivovaných příloh (= příloh už v databázi)
     */
    readonly archivovane?: PrilohaBase[];

    /**
     * Seznam příloh k nahrání - na začátku prázdný seznam, pak hodnota, která byla nastavena přes onChangeKNahrani
     */
    readonly kNahrani: PrilohaKNahrani[];

    /**
     * Maximální počet příloh, které je možné nahrát
     */
    readonly limit: number;

    /**
     * Povolené typy příloh
     */
    readonly povoleneTypyPriloh?: PovoleneTypyPriloh;

    /**
     * Kontroluje, zda byl vybrán typ přílohy u všech souborů
     */
    readonly isAllSetTypyPriloh: boolean;
    readonly isValidationError: boolean;
    /**
     * Voláno pokud se mají změnit přílohy k nahrání
     */
    readonly onChangeKNahrani: (kNahrani: PrilohaKNahrani[]) => void;

    /**
     * U nahrávané přílohy změní její typ podle ID v local state
     */
    readonly onChangeTypPrilohy: (id: number, typ: unknown) => void;

    /**
     * Nastaví hodnotu podle toho, jestli validace příloh vrátí chybu nebo ne
     */
    readonly setIsValidationError: (error: boolean) => void;
    readonly validateAllPrilohySize?: boolean;
};

type Props = {
    readonly label: string;
    readonly defaultValue?: any;
    readonly required?: boolean;
    readonly autofocus?: boolean;
    readonly multiline?: boolean;
    readonly error?: FieldError;
    readonly startAdornment?: JSX.Element;
    readonly disabled?: boolean;
    readonly selectData?: SelectData[];
    readonly tooltip?: string;
    readonly parseId?: string;
    readonly name: string;
    readonly CustomComponent?: ComponentType | ElementType;
    readonly selectedItem?: unknown;
    readonly customSelectComponent?: ReactNode;
    readonly autocomplete?: 'given-name' | 'family-name' | 'email' | 'tel' | 'street-address' | 'postal-code' | 'country' | 'organization' | 'off';
    readonly customChange?: (event: unknown) => void;
    readonly isObec?: boolean;
    readonly placeholder?: string;
    readonly runTriggerName?: string;
};

type BaseProps =
    | ({
          readonly type?: 'text' | 'select' | 'number' | 'checkbox' | 'custom';
      } & Props)
    | ({
          readonly type: 'file';
      } & FileProps &
          Props)
    | ({
          readonly type: 'date';
      } & DateProps &
          Props);

export const FormInput = (props: BaseProps) => {
    const {
        type = 'text',
        required = false,
        disabled = false,
        name,
        label,
        startAdornment,
        tooltip,
        selectData,
        CustomComponent,
        customSelectComponent,
        autocomplete,
        placeholder,
        runTriggerName,
    } = props as BaseProps & FileProps & DateProps;
    const {formatMessage} = useIntl();
    const {control, trigger} = useFormContext();

    if (type === 'select') {
        return (
            <Controller
                name={name}
                control={control}
                render={({field: {onChange, value, onBlur}, fieldState: {error}}) => (
                    <FormControl error={!!error} fullWidth margin="dense" className={error ? FIELD_WITH_ERROR_FLASH_CLASS : undefined}>
                        <InputLabel sx={{marginLeft: '-13px', marginTop: '3px'}}>
                            <Typography component="span">
                                <Box component="span" sx={{fontWeight: 'bold'}}>
                                    {`${formatMessage({id: label})}${required ? ' *' : ''}`}
                                </Box>
                            </Typography>
                        </InputLabel>
                        <Select
                            style={{marginBottom: 4}}
                            onBlur={onBlur}
                            value={value ?? ''}
                            onChange={onChange}
                            required
                            disabled={disabled}
                            autoComplete={autocomplete}
                            renderValue={(selected) => selected}
                            input={<Input startAdornment={startAdornment} />}
                            data-testid={`form-field-${name}`}
                            sx={{
                                '& .MuiSelect-icon': {
                                    color: 'primary.main',
                                    marginRight: 1.3,
                                },
                            }}
                        >
                            {!customSelectComponent &&
                                selectData?.map((item) => (
                                    <MenuItem key={item} value={item}>
                                        {item}
                                    </MenuItem>
                                ))}
                            {customSelectComponent}
                        </Select>
                        {!!error && <FormHelperText sx={{marginLeft: '0px'}}>{error.message}</FormHelperText>}
                    </FormControl>
                )}
            />
        );
    }

    if (type === 'text' || type === 'number') {
        return (
            <Controller
                control={control}
                name={name}
                render={({field, fieldState: {error}}) => (
                    <TextField
                        {...field}
                        id={field.name}
                        value={field.value ?? ''}
                        autoComplete={autocomplete}
                        disabled={disabled}
                        className={error ? FIELD_WITH_ERROR_FLASH_CLASS : undefined}
                        InputProps={{
                            inputComponent: type === 'number' ? (NumberFormatCustom as any) : undefined,
                            startAdornment: startAdornment ? <InputAdornment position="start">{startAdornment}</InputAdornment> : undefined,
                            endAdornment: tooltip ? <TooltipIcon text={tooltip} placement="top" color="primary" fontSize="small" showIcon /> : undefined,
                        }}
                        label={
                            <Typography component="span">
                                <Box component="span" sx={{fontWeight: 'bold'}}>
                                    {formatMessage({id: label})}
                                </Box>
                            </Typography>
                        }
                        fullWidth
                        variant="standard"
                        margin="dense"
                        error={!!error}
                        helperText={error?.message}
                        data-testid={`form-field-${name}`}
                        required={required}
                    />
                )}
            />
        );
    }

    if (type === 'checkbox') {
        return (
            <Controller
                name={name}
                control={control}
                render={({field: {onChange, onBlur, value, name}, fieldState: {error}}) => {
                    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
                        onChange(event);
                        if (runTriggerName) trigger(runTriggerName);
                    };
                    return (
                        <FormControl error={!!error} fullWidth margin="dense" className={error ? FIELD_WITH_ERROR_FLASH_CLASS : undefined}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={name}
                                        checked={value}
                                        value={value}
                                        onChange={handleChange}
                                        onBlur={onBlur}
                                        color="primary"
                                        disabled={disabled}
                                        data-testid={`form-field-${name}`}
                                        required={required}
                                    />
                                }
                                label={formatMessage({id: label})}
                            />
                            {!!error?.message && (
                                <Box>
                                    <FormHelperText error>{error.message}</FormHelperText>
                                </Box>
                            )}
                        </FormControl>
                    );
                }}
            />
        );
    }
    if (type === 'date') {
        return (
            <Controller
                control={control}
                name={name}
                render={({field: {onChange, value, onBlur}, fieldState: {error}}) => (
                    <KeyboardDatePickerField
                        name={name}
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        label={formatMessage({id: label})}
                        error={!!error?.message}
                        helperText={error ? error.message : null}
                        required={required}
                        disableFuture
                    />
                )}
            />
        );
    }
    if (type === 'custom' && CustomComponent) {
        return (
            <Controller
                control={control}
                name={name}
                render={({field: {onChange, value, onBlur}, fieldState: {error}}) => (
                    <CustomComponent
                        {...{error, disabled, label}}
                        name={name}
                        value={value ?? ''}
                        onBlur={onBlur}
                        onChange={onChange}
                        placeholder={placeholder}
                    />
                )}
            />
        );
    }

    return null;
};
